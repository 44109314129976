@import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap');

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background: #00b09b; /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #96c93d, #00b09b); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #96c93d,
    #00b09b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
