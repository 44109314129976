@import url(https://fonts.googleapis.com/css?family=Ubuntu&display=swap);
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-repeat: no-repeat;
  background: #00b09b; /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #96c93d, #00b09b); /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(#96c93d),
    to(#00b09b)
  );
  background: -webkit-linear-gradient(
    top,
    #96c93d,
    #00b09b
  );
  background: linear-gradient(
    to bottom,
    #96c93d,
    #00b09b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.grid-container {
  width: 50%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.Sentence {
  font-size: 5rem;
  color: #ffffff;
}

.Total {
  font-size: 1rem;
  color: #ffffff;
}

.App {
  text-align: center;
}

