.grid-container {
  width: 50%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.Sentence {
  font-size: 5rem;
  color: #ffffff;
}

.Total {
  font-size: 1rem;
  color: #ffffff;
}
